import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import store from "@/store";

// style + assets
import "@/assets/scss/style.scss";
import config from "./config";
import { AxiosInterceptor } from "@/services/AxiosInterceptor";
import { LoaderProvider } from "@/layout/LoaderContext";
import LoaderComponent from "@/layout/LoaderComponent";
import { ToastProvider } from "@/ui-component/custom-components/CustomToast";
import { OverlayProvider } from "@/views/pages/demo/OverlayContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SidebarProvider } from "./contexts/SidebarContext";

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <LoaderProvider>
        <ToastProvider>
          <AxiosInterceptor>
            <LoaderComponent />
            <OverlayProvider>
              <GoogleOAuthProvider clientId="218112593236-efgefbdbh18dao56o0heaghni8o0bc96.apps.googleusercontent.com">
                <DndProvider backend={HTML5Backend}>
                  <SidebarProvider>
                    <App />
                  </SidebarProvider>
                </DndProvider>
              </GoogleOAuthProvider>
            </OverlayProvider>
          </AxiosInterceptor>
        </ToastProvider>
      </LoaderProvider>
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
